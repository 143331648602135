var signalR = require('@microsoft/signalr');

function getConnectionInfo(url, callback) {
    $.ajax({
        type: "POST",
        url: url,
        contentType: "application/json",
        success: function (connectionInfo) {
            var connectionInfoAsJson = JSON.parse(connectionInfo);
            callback(connectionInfoAsJson);
        }
    });
}

window.InitPaymentCallbackHandler = function (paymentCallbackUrl, receiptUrl, errorUrl, callback) {
    getConnectionInfo(paymentCallbackUrl, function (info) {
        // make compatible with old and new SignalRConnectionInfo
        info.accessToken = info.AccessToken || info.accessKey; // pay attention to the case
        info.url = info.Url || info.endpoint; // pay attention to the case
        const options = {
            accessTokenFactory: () => info.accessToken
        };

        const connection = new signalR.HubConnectionBuilder()
            .withUrl(info.url, options)
            .configureLogging(signalR.LogLevel.Error)
            .build();

        connection.start().catch(console.error);

        connection.on('paymentUpdated', (paymentStatus, paymentMethod) => {
            connection.stop();

            //In case we listening on Fortus svg update it needs to be stopped.
            //See bankIdQrCodeHandler.js

            if (window.BankIdQrCodeHandler) {
                window.BankIdQrCodeHandler.stop();
            };

            if (typeof callback === 'function') {
                callback(paymentStatus);
                return;
            }

            switch (paymentStatus) {
                case 'paid':
                    location.href = receiptUrl;
                    return;
                case "timed_out":
                    errorUrl += "Payment_Timed_Out";
                    location.href = errorUrl;
                    return;
                case "cancelled":
                    errorUrl += "Payment_Cancelled";
                    location.href = errorUrl;
                    return;
                case "error":
                    errorUrl += "Payment_Error";
                    location.href = errorUrl;
                    return;
                case "paymentdeclined":
                    if (paymentMethod == 13) {
                        errorUrl += "Payment_FortusPaymentDeclined";
                    } else {
                        errorUrl += "Payment_PaymentDeclined";
                    }

                    location.href = errorUrl;
                    return;
                case "expired":
                    errorUrl += "Payment_Expired";
                    location.href = errorUrl;
                    return;
                default:
                    errorUrl += "Payment_Error";
                    location.href = errorUrl;
                    return;
            }
        });

    });
}
